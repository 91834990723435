import React, { FC } from 'react';

import { IconColors } from '@/data/icons/colors';
import { TIconColors } from '@/types/icons';

interface IProps {
	color?: TIconColors;
}

/* eslint-disable */

const RefreshIcon: FC<IProps> = ({ color }) => (
	<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fill={IconColors[color]}
			d="M19 10C18.7348 10 18.4804 10.1054 18.2929 10.2929C18.1054 10.4804 18 10.7348 18 11C18.0119 12.873 17.3703 14.6916 16.1857 16.1425C15.001 17.5933 13.3475 18.5857 11.51 18.9486C9.67239 19.3115 7.76578 19.0223 6.11851 18.1308C4.47123 17.2392 3.18641 15.8012 2.48533 14.0643C1.78425 12.3273 1.71079 10.4003 2.27762 8.61508C2.84445 6.82983 4.01607 5.29813 5.5907 4.28378C7.16533 3.26942 9.0444 2.83591 10.9043 3.05791C12.7641 3.27991 14.4884 4.14352 15.78 5.5H13.38C13.1148 5.5 12.8604 5.60536 12.6729 5.79289C12.4854 5.98043 12.38 6.23478 12.38 6.5C12.38 6.76522 12.4854 7.01957 12.6729 7.20711C12.8604 7.39464 13.1148 7.5 13.38 7.5H17.91C18.1752 7.5 18.4296 7.39464 18.6171 7.20711C18.8046 7.01957 18.91 6.76522 18.91 6.5V2C18.91 1.73478 18.8046 1.48043 18.6171 1.29289C18.4296 1.10536 18.1752 1 17.91 1C17.6448 1 17.3904 1.10536 17.2029 1.29289C17.0154 1.48043 16.91 1.73478 16.91 2V3.77C15.2447 2.17806 13.0808 1.20995 10.784 1.02933C8.48729 0.848705 6.19863 1.46664 4.30492 2.77869C2.41121 4.09074 1.02861 6.01641 0.390831 8.23019C-0.246949 10.444 -0.100787 12.8101 0.804611 14.9285C1.71001 17.047 3.31911 18.7878 5.3599 19.8568C7.40069 20.9258 9.748 21.2574 12.005 20.7955C14.2621 20.3336 16.2904 19.1065 17.7471 17.3217C19.2039 15.5369 19.9997 13.3038 20 11C20 10.7348 19.8946 10.4804 19.7071 10.2929C19.5196 10.1054 19.2652 10 19 10Z"
		/>
	</svg>
);

export default RefreshIcon;
