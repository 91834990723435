/* eslint-disable max-len */
import { Slot } from '@radix-ui/react-slot';
import { type VariantProps, cva } from 'class-variance-authority';
import React from 'react';

import { cn } from '@/utils';

const buttonVariants = cva(
	'font-sans relative inline-flex items-center justify-center whitespace-nowrap rounded-lg text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-blue focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
	{
		variants: {
			size: {
				default: 'h-10 px-6 py-2',
				sm: 'h-8 rounded px-5',
				icon: 'h-8 w-8 rounded p-1',
				iconTable: 'h-4 w-4',
				iconSm: 'h-5 w-5 p-0',
				link: 'p-0 h-auto',
			},
			variant: {
				default: 'bg-brand-blue text-white',
				secondary: 'bg-transparent text-brand-blue border-2 border-brand-blue',
				tetriary: 'bg-transparent text-brand-blue hover:text-brand-blue',
				// @deprecated use danger
				destructive: 'bg-brand-red text-white hover:bg-brand-red/90',
				danger: 'bg-brand-red text-white hover:bg-brand-red/90',
				success: 'bg-brand-green text-white hover:bg-brand-green/90',
				link: 'text-brand-blue underline-offset-4 hover:underline',
				white: 'bg-white text-brand-blue',
				input: 'border border-brand-gray-4 bg-white px-3 py-[10px] leading-tight',
				ghost: 'hover:bg-brand-blue hover:text-white',
			},
			icon: {
				left: '[&>svg]:absolute [&>svg]:left-[14px] [&>svg]:size-5 [&>svg]:min-w-5 pl-12',
				right: '[&>svg]:absolute [&>svg]:right-[14px] [&>svg]:size-5 [&>svg]:min-w-5 pr-12',
			},
			gradient: {
				true: 'bg-gradient-to-b from-white to-brand-gray-8 text-brand-dark-blue border border-brand-gray-4 px-4 py-[10px] [&>svg]:size-6 [&>svg]:min-w-6',
			},
		},
		compoundVariants: [
			{
				icon: 'left',
				size: 'sm',
				class: '[&>svg]:left-3 [&>svg]:size-4 [&>svg]:min-w-4 pl-10',
			},
			{
				icon: 'right',
				size: 'sm',
				class: '[&>svg]:right-3 [&>svg]:size-4 [&>svg]:min-w-4 pr-10',
			},
			{
				variant: 'default',
				gradient: true,
				class: 'from-brand-aqua-blue to-brand-blue text-white border-none',
			},
			{
				variant: 'secondary',
				gradient: true,
				class: '[&>svg]:text-brand-blue',
			},
			{
				icon: 'left',
				gradient: true,
				class: '[&>svg]:left-2 pl-10',
			},
			{
				icon: 'right',
				gradient: true,
				class: '[&>svg]:right-2 pr-10',
			},
			{
				size: 'icon',
				gradient: true,
				class: 'p-2',
			},
		],
		defaultVariants: {
			variant: 'default',
			size: 'default',
			gradient: false,
		},
	}
);

export interface ButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement>,
		VariantProps<typeof buttonVariants> {
	asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ className, variant, size, icon, gradient, asChild = false, ...props }, ref) => {
		const Comp = asChild ? Slot : 'button';
		return (
			<Comp
				type="button"
				className={cn(buttonVariants({ variant, size, icon, gradient, className }))}
				ref={ref}
				{...props}
			/>
		);
	}
);
Button.displayName = 'Button';

export { Button, buttonVariants };
