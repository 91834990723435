import { UserRoles } from '@/data/user/role';
import { TUsersStatus } from '@/types/users';

// @todo есть папка user, перенести

export const USERS_STATUS_CONFIG: Record<TUsersStatus, string> = {
	[UserRoles.Admin]: 'Администратор',
	[UserRoles.GlobalAdmin]: 'Глобальный администратор',
	[UserRoles.SecurityAdmin]: 'Админ. инф. безопасности',
	[UserRoles.Leader]: 'Лидер',
	[UserRoles.HeadTracker]: 'Старший трекер',
	[UserRoles.Tracker]: 'Трекер',
	[UserRoles.Expert]: 'Эксперт',
	[UserRoles.Employee]: 'Сотрудник АСИ',
	[UserRoles.Manager]: 'Руководитель АСИ',
	[UserRoles.InternationalAdmin]: 'Администратор меж.',
	[UserRoles.InternationalLeader]: 'Лидер',
	[UserRoles.InternationalTracker]: 'Трекер меж.',
	[UserRoles.InternationalExpert]: 'Эксперт меж.',
	[UserRoles.InternationalTrackerExpert]: 'Трекер меж.',
	[UserRoles.ThemeExpert]: 'Эксперт отборов',
	[UserRoles.ThemeAdmin]: 'Админ отборов',
	[UserRoles.ThemeTracker]: 'Трекер отборов',
	[UserRoles.TopManager]: 'Топ-менеджер',
	[UserRoles.ImportAdmin]: 'Импорт админ',
};

export const USER_ROLES_DECLENSION: Record<TUsersStatus, string> = {
	[UserRoles.Admin]: 'Администратором',
	[UserRoles.GlobalAdmin]: 'Глобальным администратором',
	[UserRoles.SecurityAdmin]: 'Администратором инф. безопасности',
	[UserRoles.Leader]: 'Лидером',
	[UserRoles.HeadTracker]: 'Старшим трекером',
	[UserRoles.Tracker]: 'Трекером',
	[UserRoles.Expert]: 'Экспертом',
	[UserRoles.Employee]: 'Сотрудником АСИ',
	[UserRoles.Manager]: 'Руководителем АСИ',
	[UserRoles.InternationalAdmin]: 'Администратором',
	[UserRoles.InternationalLeader]: 'Лидером',
	[UserRoles.InternationalTracker]: 'Трекером',
	[UserRoles.InternationalExpert]: 'Экспертом',
	[UserRoles.InternationalTrackerExpert]: 'Трекером',
	[UserRoles.ThemeExpert]: 'Экспертом отборов',
	[UserRoles.ThemeAdmin]: 'Админом отборов',
	[UserRoles.ThemeTracker]: 'Трекером отборов',
	[UserRoles.TopManager]: 'Топ-менеджером',
	[UserRoles.ImportAdmin]: 'Импорт админом',
};
