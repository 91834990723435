import PopupNotification from '@/domain/core/PopupNotification';
import { backendApi } from '@/utils/api';

export type TPresetPayload = {
	code: string;
	preset: string;
};
export default class Preset {
	public static getPreset = async (code: string) => {
		try {
			const {
				data: { preset },
			} = await backendApi.get<TPresetPayload>(`/presets/${code}`);
			const result = preset || '{}';
			return JSON.parse(result) as { [p: string]: string | string[] };
		} catch (e) {
			PopupNotification.emitter('error', 'Ошибка при получении пресета.');
			return null;
		}
	};

	public static deletePreset = async (code: string) => {
		try {
			await backendApi.delete(`/presets/delete/${code}`);
		} catch (e) {
			PopupNotification.emitter('error', 'Ошибка при удалении пресета.');
		}
	};

	public static deleteAllPreset = async () => {
		try {
			await backendApi.delete(`/presets/deleteAll`);
		} catch (e) {
			PopupNotification.emitter('error', 'Ошибка при удалении пресета.');
		}
	};

	public static createPreset = async (payload: TPresetPayload) => {
		try {
			const { data } = await backendApi.post<number>('/presets/create', payload);
			return data;
		} catch (e) {
			PopupNotification.emitter('error', 'Ошибка при создании пресета.');
			return null;
		}
	};
}
