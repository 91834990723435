import { FC } from 'react';

import AlertIcon from '@/components/core/icons/AlertIcon';
import ArrowDownIcon from '@/components/core/icons/ArrowDownIcon';
import CopyIcon from '@/components/core/icons/CopyIcon';
import DateIcon from '@/components/core/icons/DateIcon';
import DoneIcon from '@/components/core/icons/DoneIcon';
import DownloadIcon from '@/components/core/icons/DownloadIcon';
import EditIcon from '@/components/core/icons/EditIcon';
import FileIcon from '@/components/core/icons/FileIcon';
import FilterIcon from '@/components/core/icons/FilterIcon';
import InfoIcon from '@/components/core/icons/InfoIcon';
import LinkIcon from '@/components/core/icons/LinkIcon';
import LockIcon from '@/components/core/icons/LockIcon';
import PlusIcon from '@/components/core/icons/PlusIcon';
import RefreshIcon from '@/components/core/icons/RefreshIcon';
import SettingsIcon from '@/components/core/icons/SettingsIcon';
import { TIconType } from '@/types/icons';

export const IconsConfig: Record<TIconType, FC> = {
	alert: AlertIcon,
	download: DownloadIcon,
	edit: EditIcon,
	downArrow: ArrowDownIcon,
	filter: FilterIcon,
	date: DateIcon,
	link: LinkIcon,
	file: FileIcon,
	lock: LockIcon,
	settings: SettingsIcon,
	plus: PlusIcon,
	copy: CopyIcon,
	refresh: RefreshIcon,
	done: DoneIcon,
	info: InfoIcon,
};
