import { ReactNode } from 'react';
import { toast } from 'react-toastify';
import { Theme, ToastContent, ToastOptions } from 'react-toastify/dist/types';

export type TNotificationType = 'info' | 'success' | 'warning' | 'error' | 'default';

class PopupNotification {
	defaultConfig: ToastOptions = {
		position: 'bottom-right',
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		className: 'custom-notification',
	};

	emitter = (type: TNotificationType, message: ToastContent | string, theme?: Theme, icon?: ReactNode) => {
		if (type !== 'default') {
			return toast[type](message, { ...this.defaultConfig, theme, icon });
		}
		return toast(message, { ...this.defaultConfig, theme, icon });
	};
}

export default new PopupNotification();
