import React, { FC } from 'react';

const AlertIcon: FC = () => (
	<svg width="16" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect x="7.66669" y="4.66663" width="2.66667" height="8" fill="white" />
		<path
			d="M16.5805 11.4431L16.5737 11.4294L16.5661 11.4163L11.1674 2.08525C11.1672 2.0848 11.1669
            2.08435 11.1667 2.0839C10.4933 0.907237 8.91992 0.498438 7.75192 1.16587L7.75186
            1.16577L7.74274 1.17124C7.35739 1.40246 7.01559 1.72702 6.82694 2.0949L1.43387
            11.4163L1.43386 11.4163L1.43253 11.4186C0.76572 12.5855 1.17314 14.157 2.34729
            14.8315C2.7711 15.0845 3.19573 15.1667 3.59999 15.1667H14.3333C15.7392 15.1667
            16.8114 14.0221 16.833 12.707C16.9103 12.1657 16.7231 11.7282 16.5805 11.4431ZM8.99999 11.5C8.91361
            11.5 8.88048 11.4734 8.87021 11.4631C8.85993 11.4528 8.83332 11.4197 8.83332 11.3333C8.83332 11.2469
            8.85993 11.2138 8.87021 11.2035C8.88048 11.1933 8.91361 11.1667 8.99999 11.1667C9.08636
            11.1667 9.11949 11.1933 9.12977 11.2035C9.14005 11.2138 9.16665 11.2469 9.16665 11.3333C9.16665
            11.4197 9.14004 11.4528 9.12977 11.4631C9.11949 11.4734 9.08636 11.5 8.99999 11.5ZM9.16665
            8.66666C9.16665 8.75303 9.14005 8.78616 9.12977 8.79644C9.11949 8.80671 9.08636 8.83332
            8.99999 8.83332C8.91361 8.83332 8.88048 8.80671 8.87021 8.79644C8.85993 8.78616 8.83332
            8.75303 8.83332 8.66666V5.99999C8.83332 5.91362 8.85993 5.88049 8.87021 5.87021C8.88048
            5.85993 8.91361 5.83332 8.99999 5.83332C9.08636 5.83332 9.11949 5.85993 9.12977
            5.87021C9.14005 5.88049 9.16665 5.91362 9.16665 5.99999V8.66666Z"
			fill="#FF4646"
			stroke="white"
		/>
	</svg>
);
export default AlertIcon;
