import React from 'react';

const Logo = () => (
	<svg width="75" height="36" viewBox="0 0 75 36" fill="none" xmlns="http://www.w3.org/2000/svg">
		<mask id="mask0_4322_6224" maskUnits="userSpaceOnUse" x="22" y="5" width="23" height="18">
			<path d="M37.0945 13.698L22.3342 5.18213L44.4672 9.41783L37.1043 22.2385L37.0945 13.698Z" fill="#71B4E1" />
		</mask>
		<g mask="url(#mask0_4322_6224)">
			<rect x="21.4966" y="4.51123" width="23.4249" height="9.99438" fill="#A0CDEB" />
			<path d="M37.1043 22.2386L44.4672 9.41797L37.0945 13.6981L37.1043 22.2386Z" fill="#0055A0" />
		</g>
		<mask id="mask1_4322_6224" maskUnits="userSpaceOnUse" x="14" y="17" width="24" height="19">
			<path d="M29.7314 26.5139L14.9712 17.998L37.1041 22.2387L29.7413 35.0593L29.7314 26.5139Z" fill="#C5CAC6" />
		</mask>
		<g mask="url(#mask1_4322_6224)">
			<rect x="14.3979" y="17.3608" width="23.4248" height="9.99439" fill="#E1E6E6" />
			<path d="M29.7413 35.0594L37.1042 22.2388L29.7314 26.514L29.7413 35.0594Z" fill="#AFAFAF" />
		</g>
		<mask id="mask2_4322_6224" maskUnits="userSpaceOnUse" x="0" y="0" width="23" height="18">
			<path
				d="M14.9614 9.45725L0.201172 0.941406L22.3341 5.18205L14.9713 17.9977L14.9614 9.45725Z"
				fill="#F73624"
			/>
		</mask>
		<g mask="url(#mask2_4322_6224)">
			<rect x="-0.508789" y="0.227539" width="23.4248" height="9.99439" fill="#F7403A" />
			<path d="M14.9713 17.9978L22.3342 5.18213L14.9614 9.45733L14.9713 17.9978Z" fill="#AA272F" />
		</g>
		<path
			d="M44.6553 30.0958H47.5405L46.8346 27.7629L46.1593 25.1035H46.0519L45.3536
			27.7629L44.6553 30.0958ZM48.2004 32.4287H43.9954L43.2741 34.7615C43.2051
			35.0026 43.113 35.1115 42.76 35.1115H40.6115C40.3582 35.1115 40.3045 34.9248
			40.3583 34.746L44.1949 23.0817C44.4482 22.2263 44.7781 22.0785 45.5301
			22.063H46.6351C47.4638 22.063 47.7707 22.1719 48.0623 23.0817L51.899
			34.746C51.9527 34.8937 51.899 35.1115 51.6611 35.1115H49.4742C49.1979 
			35.1115 49.0215 35.1115 48.9447 34.816L48.2004 32.4287Z"
			fill="#00325F"
		/>
		<path
			d="M60.8651 32.2651C61.2104 32.1173 61.3178 32.2107 61.4329
			32.4906L62.0545 33.8281C62.2233 34.1781 62.1696 34.3103 61.947
			34.4424C60.6545 35.1797 59.1859 35.5403 57.7037 35.4845C53.7059
			35.4845 51.918 32.9727 51.918 28.8436C51.918 24.7144 54.3121
			22.0705 57.8341 22.0705C59.0796 22.0136 60.3187 22.2813 61.4329
			22.8481C61.7092 23.0036 61.7475 23.097 61.5787 23.5013L61.0109
			24.7844C60.8804 25.1188 60.8114 25.1965 60.4584 25.0643C59.6637
			24.7488 58.8181 24.5853 57.9646 24.5822C55.9388 24.5822 54.8569
			26.4018 54.8569 28.828C54.8569 31.2542 55.6242 32.895 58.0336
			32.895C59.0094 32.8643 59.9704 32.6453 60.8651 32.2496"
			fill="#00325F"
		/>
		<path
			d="M71.27 26.4021L69.6356 29.1938L66.5662 34.2872C66.1442
			34.9793 65.9447 35.0648 65.185 35.0648H64.4561C63.4969 35.0648
			63.259 34.5671 63.259 33.7817V22.4207C63.259 22.1563 63.3741 22.063
			63.6427 22.063H65.6071C65.9447 22.063 65.9984 22.1407 65.9984
			22.4207V30.8112H66.1058L67.6405 28.2062L70.8173 22.9261C71.2393
			22.2185 71.4465 22.0863 72.1754 22.0863H72.9428C73.8406 22.0863
			74.1322 22.4207 74.1322 23.3927V34.7771C74.1322 35.0337 74.0247
			35.127 73.7485 35.127H71.7841C71.4542 35.127 71.3774 35.057 71.3774 34.7771V26.4021H71.27Z"
			fill="#00325F"
		/>
	</svg>
);

export default Logo;
