import React from 'react';

import Logo from '@/components/core/icons/Logo';

import cn from './style.module.sass';

const NotAllowedPlatform = () => (
	<div className={cn.wrapper}>
		<div className={cn.logo}>
			<Logo />
		</div>
		<div className={cn.message}>Откройте isup.asi.ru с компьютера или ноутбука, чтобы подать заявку</div>
	</div>
);

export default NotAllowedPlatform;
