import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { FC, forwardRef, Ref } from 'react';

import IconSelector from '@/components/core/icons/IconSelector';
import { TIconColors, TIconType } from '@/types/icons';

import cn from './style.module.sass';

type TModifications = 'small';

export type ButtonPropsInitial = {
	handleClick: () => void | Promise<void>;
	className: string;
	modifications: TModifications[];
	submit: boolean;
	disabled: boolean;
	variant: 'standard' | 'outlined' | 'ghost' | 'outlined-borderless';
	href: string;
	[name: string]: unknown;
};

export type TButtonProps = Partial<ButtonPropsInitial> &
	(
		| {
				icon?: TIconType;
				iconPosition: 'left' | 'right';
				iconColor?: TIconColors;
		  }
		| { icon?: never; iconPosition?: 'left' | 'right'; iconColor?: TIconColors }
	);

const Button: FC<TButtonProps> = forwardRef(
	(
		{
			className,
			modifications,
			submit,
			handleClick,
			disabled = false,
			variant = 'standard',
			href,
			children,
			icon,
			iconPosition,
			iconColor,
			...rest
		},
		ref: Ref<HTMLButtonElement>
	) => {
		const { push } = useRouter();

		return (
			<button
				ref={ref}
				type={submit ? 'submit' : 'button'}
				onClick={href ? () => push(href) : handleClick}
				disabled={disabled}
				className={clsx(
					cn.button,
					className,
					icon && cn[`button--icon`],
					variant && cn[`button--${variant}`],
					modifications?.map((mod) => cn[`button--${mod}`])
				)}
				{...rest}
			>
				<span className={cn.content}>
					{icon && iconPosition === 'left' && (
						<span className={cn.icon}>
							<IconSelector type={icon} color={iconColor} />
						</span>
					)}
					<span className={cn.text}>{children}</span>
					{icon && iconPosition === 'right' && (
						<span className={cn.icon}>
							<IconSelector type={icon} color={iconColor} />
						</span>
					)}
				</span>
			</button>
		);
	}
);

export default Button;
