import clsx from 'clsx';
import Link from 'next/link';
import React, { FC } from 'react';

import cn from './style.module.sass';

type BaseLinkProps = {
	href: string;
	className?: string;
	title?: string;
	dataTestId?: string;
};

const BaseLink: FC<BaseLinkProps> = ({ children, href, className, dataTestId, title }) => (
	<Link href={href} passHref>
		<a data-testid={dataTestId} href={href} className={clsx(cn.link, className)} title={title}>
			{children}
		</a>
	</Link>
);

export default BaseLink;
