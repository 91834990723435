import React, { FC } from 'react';

import { IconsConfig } from '@/data/icons/config';
import { TIconColors, TIconType } from '@/types/icons';

interface IProps {
	type: TIconType;
	color?: TIconColors;
}

const IconSelector: FC<IProps> = ({ type, ...rest }) => {
	const Icon = IconsConfig?.[type];

	return <Icon {...rest} />;
};
export default IconSelector;
