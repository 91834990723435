import React, { FC } from 'react';

import { IconColors } from '@/data/icons/colors';
import { TIconColors } from '@/types/icons';

interface IProps {
	color: TIconColors;
}

const ArrowDownIcon: FC<IProps> = ({ color }) => (
	<svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M1 1L5 5L9 1" stroke={IconColors[color]} strokeWidth="2" />
	</svg>
);
export default ArrowDownIcon;
