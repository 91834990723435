import React, { FC } from 'react';

import { IconColors } from '@/data/icons/colors';
import { TIconColors } from '@/types/icons';

interface IProps {
	color?: TIconColors;
}

/* eslint-disable */

const DoneIcon: FC<IProps> = ({ color }) => (
	<svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			stroke={IconColors[color]}
			d="M1 5.32143L7.42222 12L18 1"
			stroke-width="2"
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
);

export default DoneIcon;
