import Bowser from 'bowser';
import { useLayoutEffect, useState } from 'react';
import { useWindowSize } from 'react-use';

const usePlatformDetect = () => {
	const [platform, setPlatform] = useState<string>(null);
	const { width } = useWindowSize();
	useLayoutEffect(() => {
		const detectPlatform = () => {
			if (typeof window !== 'undefined') {
				const { userAgent } = window.navigator;
				const {
					platform: { type },
				} = Bowser.parse(userAgent);
				setPlatform(type);
			}
		};
		detectPlatform();
	}, [width]);

	return platform;
};

export default usePlatformDetect;
